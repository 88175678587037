import './vendor/jquery.js'
import './vendor/gsap.min.js'
import './vendor/ScrollTrigger.min.js'
import './vendor/Vibrant.min.js'
import './tictoc/environment.js.erb'
import './tictoc/preview.js'
import './tictoc/ui.js.coffee'
import './speyburn/accordion.js.coffee'
import './speyburn/age_gate.js.coffee'
import './speyburn/banners.js.coffee'
import './speyburn/cookie_notice.js.coffee'
import './speyburn/custom_checkbox.js.coffee'
import './speyburn/custom_file.js.coffee'
import './speyburn/custom_radio.js.coffee'
import './speyburn/custom_select.js.coffee'
import './speyburn/gallery.js.coffee'
import './speyburn/header.js.coffee'
import './speyburn/image_block.js.coffee'
import './speyburn/maps.js.coffee'
import './speyburn/menu.js.coffee'
import './speyburn/story_block.js.coffee'
import './speyburn/video.js.coffee'

var ready;

ready = function (callback) {
  if (document.readyState !== 'loading') {
    callback();
  } else if (document.addEventListener) {
    document.addEventListener('DOMContentLoaded', callback);
  } else {
    document.attachEvent('onreadystatechange', function () {
      if (document.readyState === 'complete') {
        callback();
      }
    });
  }
};

ready(function () {
  tictoc.preview.setup();
  tictoc.ui.setup();
  speyburn.age_gate.setup();
  speyburn.menu.setup();
  speyburn.header.setup();
  speyburn.banners.setup();
  speyburn.gallery.setup();
  speyburn.maps.setup();
  speyburn.video.setup();
  speyburn.image_block.setup();
  speyburn.story_icon_block.setup();
  speyburn.cookie_notice.setup();
  speyburn.accordion.setup();
  speyburn.custom_select.setup();
  speyburn.custom_radio.setup();
  speyburn.custom_checkbox.setup();
  speyburn.custom_file.setup();
});
