window.speyburn ||= {}

speyburn.header =
  setup: ->
    @add_listeners()

  add_listeners: ->
    tl = gsap.timeline(
      scrollTrigger: {
        trigger: 'body',
        start: '50px top',
        toggleActions: 'play play none reverse',
        onEnter: (e) => e.trigger.classList.add('reduced'),
        onLeaveBack: (e) => e.trigger.classList.remove('reduced')
      }
    )

    # Hide and show
    if window.innerWidth > 1023
      if document.querySelector('.alert_banner')
        alert_height = document.querySelector('.alert_banner').offsetHeight
      else
        alert_height = 0
      
      tl.to("header", {duration: .2, yPercent: -100, y: -2}, 0)
      tl.to("header", {height: alert_height + 64, background: 'linear-gradient(to right, #658005 0%, #3D5719 100%)'}, .2)
      tl.to("header .logo #text, header .logo #est, header .logo #salmon, header .logo #river", {opacity: 0}, .2)
      tl.to("header .header_border", {opacity: 1}, .2)
      if window.innerWidth > 1279
        tl.to(".primary_nav", {y: -42}, .2)
      else if window.innerWidth > 1023
        tl.to(".primary_nav", {y: -31}, .2)
      tl.to("header", {duration: .2, yPercent: 0, y: 0}, .4)
    else
      if document.querySelector('body').classList.contains('large_banner') || document.querySelector('body').classList.contains('small_banner') 
        tl.to("header", {background: 'linear-gradient(to right, #658005 0%, #3D5719 100%)', duration: .2})
        tl.to("header .header_border", {opacity: 1, duration: .2}, 0)

      # Resize in place
      # tl.to("header", {duration: .2, height: 64}, 0)
      # tl.to("header .logo #text, header .logo #est, header .logo #salmon", {duration: .2, opacity: 0}, 0)
      # if window.innerWidth > 1279
      #   tl.to(".primary_nav", {duration: .2, y: -42}, 0)
      # else
      #   tl.to(".primary_nav", {duration: .2, y: -31}, 0)