window.tictoc ||= {}

tictoc.ui =
  setup: ->
    @setup_js_class()
    @scroll_to_anchor()
    @set_content_padding()
    @set_banner_padding()
    
    gsap.registerPlugin(ScrollTrigger)

  setup_js_class: ->
    $('html').removeClass('no-js').addClass('js')

  scroll_to_anchor: ->
    document.querySelectorAll('.scroll_to_anchor').forEach (el) ->
      el.addEventListener 'click', (e) ->
        e.preventDefault()
        document.querySelector(e.target.hash).scrollIntoView behavior: 'smooth'
  
  set_content_padding: ->
    header_height = document.querySelector('header').offsetHeight
    document.querySelector('#content').style.paddingTop = "#{header_height}px"
  
  set_banner_padding: ->
    banner = document.querySelector('.banner')
    return false unless banner
    
    header_height = document.querySelector('header').offsetHeight
    banner.style.paddingTop = "#{header_height + 100}px"
