window.speyburn ||= {}

speyburn.image_block =
  setup: ->
    image_blocks = document.querySelectorAll(".image_block_content_block.parallax")
    return false unless image_blocks.length > 0

    image_blocks.forEach (block) ->
      gsap.to(block.querySelector('img'), {
        objectPosition: "50% 40%",
        ease: "none",
        scrollTrigger: {
          trigger: block,
          start: "top bottom",
          end: "bottom top",
          scrub: true
        }
      })