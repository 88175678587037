class CustomFile
  constructor: (@file_input)->
    link_text = if @file_input.getAttribute('link-text') then @file_input.getAttribute('link-text') else "Choose file"

    @file_input_wrapper = document.createElement('div')
    @file_input_wrapper.classList.add('custom_file_wrapper')

    @file_input.parentElement.insertBefore(@file_input_wrapper, @file_input)
    @file_input_wrapper.appendChild(@file_input)

    @wrapper = @file_input.parentNode

    file_link = document.createElement('a')
    file_link.href = '#'
    file_link.innerHTML = "#{link_text}"

    file_text = document.createElement('span')
    file_text.classList.add('filename')
    file_text.textContent = "No file chosen"

    @wrapper.appendChild(file_link)
    @wrapper.appendChild(file_text)

    @link = @wrapper.querySelector('a')

    @add_listeners()

  add_listeners: =>
    @file_input.addEventListener 'change', =>
      @update_filename_text()
    
    @link.addEventListener 'click', (e) =>
      e.preventDefault()
      @trigger_file_input()
  
  trigger_file_input: =>
    @file_input.click()

  update_filename_text: =>
    url_string_parts = @file_input.value.split('\\')
    @wrapper.querySelector('.filename').textContent = url_string_parts[url_string_parts.length - 1]

window.speyburn ||= {}

window.speyburn.custom_file =
  setup: ->
    document.querySelectorAll('input[type=file]').forEach (file_input) ->
      custom_select = new CustomFile(file_input)