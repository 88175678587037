window.speyburn ||= {}

video_links = document.querySelectorAll('.video_source, .video_link, .ugc a[href*="youtube.com"], .ugc a[href*="youtu.be"], .ugc a[href*="vimeo.com"], .ugc a[href*=".mp4"]')

speyburn.video =
  setup: ->

    return false unless video_links.length > 0

    if !document.cookie.match(/speyburn_cookies_consent=true/)
      video_span = document.createElement('span')
      video_span.innerHTML = ' We want to give you the best possible experience whilst viewing our website, and to do this we use cookies. Check out our <a href="/cookie-policy">cookie policy</a> for info on cookies and tracking technology. To view this functionality please <a href="/cookies-accepted" class="accept_cookies">accept cookies</a>.'
      video_links.forEach (link) ->
        link.after(video_span)
    else
      youtube_api = document.createElement('script')
      youtube_api.src = '//www.youtube.com/iframe_api'
      document.querySelector('body').append(youtube_api)


      vimeo_api = document.createElement('script')
      vimeo_api.src = '//player.vimeo.com/api/player.js'
      document.querySelector('body').append(vimeo_api)

      setTimeout ->
        video_links.forEach (link) ->
          if link.nodeName.match(/VIDEO/)
            # CMS video
            player = link
            player.controls = true
            if link.parentNode.querySelector('.video_cover')
              link.parentNode.querySelector('.video_cover').addEventListener 'click', (e) ->
                e.preventDefault()
                this.style.display = 'none'
                player.play()
          else
            # Third party video
            if link.href.match(/youtube.com/) || link.href.match(/youtu.be/) || link.href.match(/vimeo.com/)
              if link.parentNode.querySelector('.video_cover')
                link.parentNode.querySelector('.video_cover').addEventListener 'click', (e) ->
                  e.preventDefault()
                  this.style.display = 'none'
                  player.playVideo()

              if link.closest('.ugc')
                video_wrapper = document.createElement('div')
                video_wrapper.classList.add('video_wrapper')
                link.parentElement.insertBefore(video_wrapper, link)
                video_wrapper.appendChild(link)

            if link.href.match(/youtube.com/)
              player = new (YT.Player)(link,
                videoId: "#{link.href.match(/v=([\w\-]+)/)[1]}")

            if link.href.match(/youtu.be/)
              player = new (YT.Player)(link,
                videoId: "#{link.href.match(/\.be\/([\w\-]*)/)[1]}")

            if link.href.match(/vimeo.com/)
              vimeo_video_container = document.createElement('div')
              vimeo_video_parent = link.parentNode
              vimeo_video_parent.insertBefore(vimeo_video_container, link)
              player = new Vimeo.Player(vimeo_video_container,
                id: link.href.match(/vimeo\.com.*\/(\d+$)/)[1])

              link.remove()
      , 1000

