window.speyburn ||= {}

speyburn.menu =
  setup: ->
    @add_listeners()

  add_listeners: ->
    return false unless document.querySelectorAll('.menu_toggle').length > 0

    header_height = document.querySelector('header').offsetHeight
    document.querySelector('.primary_nav').style.top = "#{header_height}px"

    document.querySelector('.menu_toggle').addEventListener 'click', (e) ->
      e.preventDefault()
      e.srcElement.classList.toggle('active')

      tl = gsap.timeline()

      if e.srcElement.classList.contains('active')
        tl.to(".menu_toggle rect:first-child", {duration: 0.2, y: 7}, 0)
        tl.to(".menu_toggle rect:last-child", {duration: 0.2, y: -7}, 0)
        tl.to(".menu_toggle rect:last-child", {duration: 0.2, opacity: 0}, 0)
        tl.to(".menu_toggle rect:first-child", {duration: 0.2, rotate: -45, transformOrigin: 'center center'}, 0.2)
        tl.to(".menu_toggle rect:nth-child(2)", {duration: 0.2, rotate: 45, transformOrigin: 'center center'}, 0.2)
      else
        tl.to(".menu_toggle rect:nth-child(2)", {duration: 0.2, rotate: 0, transformOrigin: 'center center'}, 0)
        tl.to(".menu_toggle rect:first-child", {duration: 0.2, rotate: 0, transformOrigin: 'center center'}, 0)
        tl.to(".menu_toggle rect:last-child", {duration: 0.2, opacity: 1}, 0.2)
        tl.to(".menu_toggle rect:last-child", {duration: 0.2, y: 0}, 0.2)
        tl.to(".menu_toggle rect:first-child", {duration: 0.2, y: 0}, 0.2)

      document.querySelector('body').classList.toggle('menu_open')
      e.srcElement.blur()

    document.querySelectorAll('.section_toggle').forEach (link) ->
      link.addEventListener 'click', (e) ->
        e.preventDefault()

        link.classList.toggle('active')

        if link.classList.contains('active')
          link.nextElementSibling.style.maxHeight = "#{link.nextElementSibling.scrollHeight}px"
        else
          link.nextElementSibling.style.maxHeight = 0

        link.closest('li').classList.toggle('open')
        link.blur()

    document.querySelectorAll('.dropdown.open').forEach (open_dropdown) ->
      setTimeout ->
        open_dropdown.style.maxHeight = "#{open_dropdown.scrollHeight}px"
      , 200


    if window.innerWidth > 1023
      document.querySelectorAll('.dropdown').forEach (dropdown) ->
        dropdown.classList.remove('open')

      document.querySelectorAll('.primary_nav .menu_link.level_1.has_children').forEach (dropdown) ->
        if dropdown.getBoundingClientRect().left + 180 >= window.innerWidth
          dropdown.querySelector('.dropdown').classList.add('reverse')

      document.querySelectorAll('.primary_nav .menu_link.level_1.has_children').forEach (link) ->
        link.addEventListener 'mouseenter', (e) ->
          e.target.parentNode.querySelector('.dropdown').classList.add('open')

        link.addEventListener 'focus', (e) ->
          e.target.parentNode.querySelector('.dropdown').classList.add('open')

      document.querySelectorAll('.primary_nav .menu_link.level_1.has_children').forEach (link) ->
        link.addEventListener 'mouseleave', (e) ->
          e.target.parentNode.querySelector('.dropdown').classList.remove('open')

      document.querySelectorAll('.primary_nav .dropdown').forEach (link) ->
        link.addEventListener 'mouseenter', (e) ->
          e.target.classList.add('open')

      document.querySelectorAll('.primary_nav .dropdown').forEach (link) ->
        link.addEventListener 'mouseleave', (e) ->
          e.target.classList.remove('open')