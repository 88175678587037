window.speyburn ||= {}

window.speyburn.cookie_notice =
  setup : ->
    return false if document.cookie.match(/speyburn_cookies_consent/)
    $('.accept_cookies').on 'click', (e) ->
      e.preventDefault()
      url = $(e.target).attr('href')
      if url == '#'
        document.cookie = 'speyburn_cookies_consent=true; path=/'
        $('.cookie_notice').hide()
        window.location.reload()
      else
        $.ajax
          url: url
        .fail ->
          document.cookie = 'speyburn_cookies_consent=true; path=/'
        .always (data, status, xhr) ->
          $('.cookie_notice').hide()
          window.location.reload()

    $('#decline_cookies').on 'click', (e) ->
      e.preventDefault()
      url = $(e.target).attr('href')
      if url == '#'
        document.cookie = 'speyburn_cookies_consent=false; path=/'
        $('.cookie_notice').hide()
      else
        $.ajax
          url: url
        .fail ->
          document.cookie = 'speyburn_cookies_consent=false; path=/'
        .always (data, status, xhr) ->
          $('.cookie_notice').hide()
