window.speyburn ||= {}

speyburn.story_icon_block =
  setup: ->
    story_icon_blocks = document.querySelectorAll(".story_icon_block_content_block, .story_number_block_content_block")
    return false unless story_icon_blocks.length > 0

    ScrollTrigger.matchMedia({
      "(max-width: 1023px)": ->
        story_icon_blocks.forEach (block) ->
          gsap.to(block.querySelector('img:nth-child(2'), {
            y: "-75",
            ease: "none",
            scrollTrigger: {
              trigger: block,
              start: "top bottom",
              end: "bottom top",
              scrub: 1
            }
          })
      
      "(min-width: 1024px)": ->
        story_icon_blocks.forEach (block) ->
          gsap.to(block.querySelector('img:nth-child(2'), {
            y: "-150",
            ease: "none",
            scrollTrigger: {
              trigger: block,
              start: "top bottom",
              end: "bottom top",
              scrub: 1
            }
          })
      
      "(min-width: 1280px)": ->
        story_icon_blocks.forEach (block) ->
          gsap.to(block.querySelector('img:nth-child(2'), {
            y: "-225",
            ease: "none",
            scrollTrigger: {
              trigger: block,
              start: "top bottom",
              end: "bottom top",
              scrub: 1
            }
          })
    });