window.speyburn ||= {}

window.speyburn.maps =
  api_key: ''
  pin: ''
  styles: [
    {
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#61673e"
        },
        {
          "lightness": 45
        }
      ]
    },
    {
      "elementType": "labels.icon",
      "stylers": [
        {
          "color": "#61673e"
        },
        {
          "visibility": "off"
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#61673e"
        },
        {
          "lightness": -25
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#b1b889"
        },
        {
          "weight": 2
        }
      ]
    },
    {
      "featureType": "administrative.land_parcel",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#bcbcbc"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#61673e"
        },
        {
          "lightness": 35
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "lightness": 10
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#61673e"
        },
        {
          "lightness": 75
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#61673e"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#61673e"
        },
        {
          "lightness": 95
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#61673e"
        },
        {
          "lightness": 5
        }
      ]
    },
    {
      "featureType": "transit.line",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#61673e"
        }
      ]
    },
    {
      "featureType": "transit.station",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#61673e"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#d4def2"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#728fcb"
        }
      ]
    }
  ]

  setup: ->
    return false unless document.querySelector('.map')
    speyburn.maps.api_key = document.querySelector('.map').getAttribute('data-key')

    !((d, s, id)->
      fjs = d.getElementsByTagName(s)[0]
      if !d.getElementById(id)
        js = d.createElement(s)
        js.id = id
        js.src = "https://maps.googleapis.com/maps/api/js?key=#{speyburn.maps.api_key}&callback=speyburn.maps.initialize"
        fjs.parentNode.insertBefore(js,fjs)
      else
        speyburn.maps.initialize()
    )(document,"script","gmap_loader")

  initialize: ->
    document.querySelectorAll('.map').forEach (location) ->
      map = speyburn.maps.create_map(location)
      if document.querySelector('.map_block_content')
        info_window = new google.maps.InfoWindow({
          content: document.querySelector('.map_block_content').outerHTML
        })
        marker = speyburn.maps.marker(location, map, info_window)
      else
        marker = speyburn.maps.marker(location, map)
      google.maps.event.addDomListener window, 'resize', ->
        map.setCenter(marker.getPosition())

  create_map: (location) ->
    options =
      center: new google.maps.LatLng(location.getAttribute('data-lat'), location.getAttribute('data-lng'))
      mapTypeId: google.maps.MapTypeId.ROADMAP
      zoom: 16
      disableDefaultUI: true
      zoomControl: false
      styles: speyburn.maps.styles

    new google.maps.Map(location, options)

  marker: (location, map, info_window)->
    if window.innerWidth > 1023
      icon_size = new google.maps.Size(80, 104)
    else
      icon_size = new google.maps.Size(56, 73)
    icon = {url: location.getAttribute('data-pin'), scaledSize: icon_size}
    options = {map: map, optimized: false, position: map.getCenter(), icon: icon}
    marker = new google.maps.Marker({})
    marker.setOptions options
    if info_window
      marker.addListener 'click', ->
        info_window.open(map, marker)
      info_window.open(map, marker)

    return marker