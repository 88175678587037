window.speyburn ||= {}

speyburn.custom_radio =
  setup: ->
    radio_buttons = document.querySelectorAll('input[type=radio]')
    
    radio_buttons.forEach (radio) ->
      label = document.querySelector("label[for='#{radio.id}']")
      label.classList.add('custom_radio_label')
      label.classList.add('checked') if radio.checked

      radio_wrapper = document.createElement('div')
      radio_wrapper.classList.add('custom_radio_wrapper')

      radio.parentElement.insertBefore(radio_wrapper, radio)
      radio_wrapper.appendChild(radio)

      radio.addEventListener 'change', =>
        document.querySelectorAll("input[name='#{radio.name}']:not(#{radio.name})").forEach (radio) ->
          label = document.querySelector("label[for='#{radio.id}']")
          if radio.checked
            label.classList.add('checked')
          else
            label.classList.remove('checked')
      
      radio.addEventListener 'focus', ->
        label = document.querySelector("label[for='#{radio.id}']")
        label.classList.add('focus')
    
      radio.addEventListener 'blur', ->
        label = document.querySelector("label[for='#{radio.id}']")
        label.classList.remove('focus')