window.speyburn ||= {}

speyburn.banners =
  setup: ->
    @setup_large_banner()

  setup_large_banner: ->
    return false unless document.querySelector(".large_banner")

    tl1 = gsap.timeline(
      scrollTrigger: {
        trigger: '.banner',
        start: 'bottom bottom',
        end: 'bottom -=300'
        toggleActions: 'play play none reverse',
        scrub: 1
      }
    )

    tl1.to(".banner_content", {scale: 0.5, opacity: 0.2}, 0)